
  import { getCookie, setCookie } from "../../utils/tools";
  import { client } from '@/client';
  import { defineComponent } from 'vue';
  import { ElMessage } from "element-plus";
  import { AdminStatus } from "@/shared/types/AdminData";
import { ShopData, ShopDataType } from "@/shared/types/ShopData";
  export default defineComponent ({
    name: "admin-edit",
    data: () => {
      return {
        formLabelWidth:"120px",
        params: {
          id:0,
          price:0,
          amount:0,
          mkamount:0,
          give:0,
          name:"",
          type:ShopDataType.GOLD,
          ctime:new Date(),
          utime:new Date()
        } as ShopData,
        paramsRules: {
            price: [
            { required: true, message: "请输入价格", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          amount: [
            { required: true, message: "请输入获得数量", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          mkamount: [
            { required: true, message: "请输入市场价数量", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
          give: [
            { required: true, message: "请输入赠送比例", trigger: "blur" },
            {
                type:'number',
                message: "请输入数字",
                trigger: "blur",
            },
          ],
            name: [
            { required: true, message: "请输入名称", trigger: "blur" },
            {
              min: 2,
              max: 20,
              message: "请输入2到20个字符名称",
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {},
    async created() {
      this.params.id = parseInt(this.$route.params.id as string);
      await this.detail();
    },
    methods: {
      // 文章详情
      async detail() {
        try {
          let res = await client.callApi("shop/Detail",{id:this.params.id});
          if (res.isSucc) {
            let data = res.res;
            this.params = {
                ...data
            };
          }
        } catch (error) {
          console.error(error);
        }
      },
  
      //更新
      async update() {
          let res = await client.callApi("shop/Update",this.params);
          if (res.isSucc) {
            ElMessage({
                message: "更新成功^_^",
                type: "success",
            });
            this.$router.go(-1);
          }else{
            ElMessage({
                message: res.err.message,
                type: "error",
            });
          }
      },
  
      submit(formName: string) {
        let refs:any = this.$refs[formName];
        if (refs) {
            refs.validate((valid: any) => {
            if (valid) {
              this.update();
            } else {
              console.log("error submit!!");
              return false;
            }
          });
        }
      },
    },
  });
  