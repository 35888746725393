import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mr-10 mt-30 ml-10 mb-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_form, {
      ref: "params",
      model: _ctx.params,
      rules: _ctx.paramsRules,
      "label-width": "84px",
      class: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_form_item, {
          label: "ID",
          prop: "id",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.id,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.params.id) = $event)),
              disabled: true
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "价格",
          prop: "price",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.price,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.params.price) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "市场价数量",
          prop: "mkamount",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.mkamount,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.params.mkamount) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "获得数量",
          prop: "amount",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.amount,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.params.amount) = $event)),
              modelModifiers: { number: true }
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "赠送比例",
          prop: "give",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_tooltip, {
              class: "item",
              effect: "dark",
              content: "请输入0.01至1的数字",
              placement: "top"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  modelValue: _ctx.params.give,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.params.give) = $event)),
                  modelModifiers: { number: true }
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "名称",
          prop: "name",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_input, {
              modelValue: _ctx.params.name,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.params.name) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "类型",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_radio, {
              modelValue: _ctx.params.type,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.params.type) = $event)),
              label: "1"
            }, {
              default: _withCtx(() => [
                _createTextVNode("金币")
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createVNode(_component_el_radio, {
              modelValue: _ctx.params.type,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.params.type) = $event)),
              label: "2"
            }, {
              default: _withCtx(() => [
                _createTextVNode("贝壳")
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "更新时间",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.params.ctime,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.params.ctime) = $event)),
              type: "datetime",
              placeholder: "选择日期时间"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, {
          label: "发布时间",
          "label-width": _ctx.formLabelWidth
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_date_picker, {
              modelValue: _ctx.params.ctime,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.params.ctime) = $event)),
              type: "datetime",
              placeholder: "选择日期时间"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }, 8, ["label-width"]),
        _createVNode(_component_el_form_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.submit('params')))
            }, {
              default: _withCtx(() => [
                _createTextVNode("保存")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules"])
  ]))
}